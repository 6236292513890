import React from 'react';
import * as Styled from './slpHeaderStyle';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { useContentfulImages } from '../../hooks/useContentfulImages';

const SlpHeader = ({ sectionData }) => {
  const optionsMainStyle = {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        const asset = useContentfulImages(node.data.target.sys.id);
        return asset.node.file.url.includes('image') ? (        
              <Styled.LogoStyle src={asset.node.file.url} alt={asset.node.title} />
         ) : (
          <></>
        );
      }
    },
  };
  return (
    <Styled.Head>
      {sectionData?.contentDetails &&
          documentToReactComponents(
            JSON.parse(sectionData?.contentDetails?.raw),
            optionsMainStyle
          )}
    </Styled.Head>
  );
};

export default SlpHeader;
