import styled from 'styled-components';

export const Head = styled.div`
  height: auto;
  background: #6e2b8b;
  padding: 30px;
  text-align: center;
  font-family: 'Figtree-Regular', arial, sans-serif;
  font-size: 22px;
  line-height: 32px;
`;

export const LogoStyle = styled.img`
  margin-bottom: 0;
  width: 100%;
  max-width: 350px;
  height: auto;
  border: 0;
  vertical-align: top;
  @media (max-width: 767px) {
    max-width: 250px;
    width: 100%;
  }
`;
